import React from 'react';
import Layout from '../components/Layout';
import LoadingPage from '../components/LoadingPage';

const ComingSoonPage = () => (
  <Layout logoHeader={true}>
    <LoadingPage text="Coming Soon" />
  </Layout>
);

export default ComingSoonPage;
