import React from 'react';
import { Flex, Image } from 'rebass/styled-components';
import StyledRectangle from '../components/StyledRectangle';
import CardTitle from '../components/CardTitle';

import animationCircuit from '../../assets/images/animation_circuit.gif';

const LoadingPage = ({ text }) => {
  return (
    <Flex
      flexDirection="column"
      maxWidth="550px"
      justifyContent="center"
      alignContent="center"
      pb={5}
      mx={3}
    >
      <StyledRectangle bg="white" px={5} py={5}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <CardTitle color="red" textAlign="center">
            {text}
          </CardTitle>
          <Image src={animationCircuit} alt="animation-circuit" />
        </Flex>
      </StyledRectangle>
    </Flex>
  );
};

export default LoadingPage;
