import React from 'react';
import { Heading } from 'rebass/styled-components';
import styled from 'styled-components';

const StyledTitle = styled(Heading)`
  font-family: Poppins;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.43px;
`;

const CardTitle = ({ children, mx, my, color, textAlign }) => {
  return (
    <StyledTitle
      mx={mx}
      my={my}
      color={color ? color : '#161f26'}
      textAlign={textAlign ? textAlign : 'left'}
    >
      {children}
    </StyledTitle>
  );
};

export default CardTitle;
