import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

const Rectangle = styled(Box)`
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 1px;
  width: 100%;
  height: 100%;
`;

const StyledRectangle = ({
  children,
  bg,
  px,
  py,
  mx,
  my,
  width,
  highlighted,
  height
}) => {
  return (
    <Rectangle
      backgroundColor={bg}
      px={px}
      py={py}
      mx={mx}
      my={my}
      width={width}
      height={height}
      sx={{
        borderWidth: highlighted ? `3px` : `0px`,
        borderStyle: highlighted ? `solid` : `solid`,
        borderColor: highlighted ? `#f46464` : `#f46464`
      }}
    >
      {children}
    </Rectangle>
  );
};

export default StyledRectangle;
